  import React from 'react';
  import './possibility.css';
  import possibility from './../../assets/possibility.png'
  const Possibility = () => (
  <div style={{backgroundImage: 'linear-gradient(103.22deg, #db8eff -13.86%, #0aefe9 99.55%)',
  borderRadius: '15px',padding: '4px' }} className="container__possibility "> 
   <div className="Horizon__possibility " id="possibility">
    
    <div className="Horizon__possibility-image">
        <img src={possibility} alt="possibility" />
      </div>
      <div className="Horizon__possibility-content">
        <h4>Request Early Access to Get Started</h4>
        <h1 className="gradient__text">The possibilities are <br /> beyond your imagination</h1>
        <p>    File scanning: The website can allow users to upload files for scanning and check if they contain any malicious code.

URL scanning: The website can scan URLs and check if they are safe to visit.

Malware database: The website can maintain a database of known malware and allow users to check if a file or URL is listed as malware.

Real-time protection: The website can provide real-time protection by scanning files and URLs in real-time and alerting users if any malware is detected.
</p>
        <h4>Request Early Access to Get Started</h4>
      </div>
      </div>
      </div>
  );
  export default Possibility
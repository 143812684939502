import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import './DataTable.css';
import { getDatabase, ref, onValue, off, orderByChild, startAt, endAt } from 'firebase/database';

// Initialize Firebase
const firebaseConfig = {
  // Your Firebase configuration
  
  apiKey: "AIzaSyCgGfat13T_Uomsx6XZSnz3uRmyIumsiB0",

  authDomain: "malware-analysis-7dadc.firebaseapp.com",

  databaseURL: "https://malware-analysis-7dadc-default-rtdb.firebaseio.com",

  projectId: "malware-analysis-7dadc",

  storageBucket: "malware-analysis-7dadc.appspot.com",

  messagingSenderId: "1360987154",

  appId: "1:1360987154:web:9041f65fe85a629c0884cb",

  measurementId: "G-6S6JG9J7F"
};

initializeApp(firebaseConfig);

const DataTable = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const messagesRef = ref(getDatabase(), 'Scan_Results');

    const onMessageValue = onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      const messageArray = data ? Object.values(data) : [];
      // Sort messages by timestamp in descending order
      const sortedMessages = messageArray.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      // Update state with at most 25 entries
      setMessages(sortedMessages.slice(0, 25));
    });

    return () => {
      off(messagesRef, 'value', onMessageValue);
    };
  }, []);

  const formatTimestamp = (timestamp) => {
    return timestamp; // No formatting of timestamp
  };

  return (
    <div style={{backgroundImage: 'linear-gradient(103.22deg, #db8eff -13.86%, #0aefe9 99.55%)',
    borderRadius: '15px',padding: '4px' }} className="container__DataTable "> 
     <div className="Horizon__DataTable" id="DataTable">
    
      <div className='Horizon__DataTable-content'>
      <h1 className="gradient__text">Scan Data</h1>
      <table className='ScanData_Table' style={{Align:'center'}}>
        
        <thead>
          <tr>
            <th style={{ padding: '10px' }}>File Name</th>
            <th style={{ paddingLeft: '25px' ,paddingRight:'25px',paddingTop:'10px',paddingBottom:'10px' }}>File Health</th>
            <th style={{ paddingLeft: '25px' ,paddingRight:'25px',paddingTop:'10px',paddingBottom:'10px' }}>Scan Time</th>
          </tr>
        </thead>
        <tbody>
          {messages.map((message, index) => (
            <tr key={index}>
              <td style={{ padding: '10px' }}>{message.file_name}</td>
              <td style={{ paddingLeft: '25px' ,paddingRight:'25px',paddingTop:'10px',paddingBottom:'10px' }}>{message.message}</td>
              <td style={{ paddingLeft: '25px' ,paddingRight:'25px',paddingTop:'10px',paddingBottom:'10px' }}>{formatTimestamp(message.timestamp)}</td>
            </tr>
          ))}
        </tbody>
      </table></div>
    </div>
    </div>
  );
};

export default DataTable;

// import React, { useEffect, useState } from 'react';
// import { initializeApp } from 'firebase/app';
// import './DataTable.css';
// import { getDatabase, ref, onValue, off } from 'firebase/database';

// // Initialize Firebase
// const firebaseConfig = {
//   // Your Firebase configuration
// rementId: "G-VYG96WJXQL"

  
// };

// initializeApp(firebaseConfig);

// const DataTable = () => {
//   const [messages, setMessages] = useState([]);

//   useEffect(() => {
//     const messagesRef = ref(getDatabase(), 'Scan_Results');

//     const onMessageValue = onValue(messagesRef, (snapshot) => {
//       const data = snapshot.val();
//       const messageArray = data ? Object.values(data) : [];

//       // Limit the number of messages to 25
//       const limitedMessages = messageArray.slice(-25);
//       setMessages(limitedMessages);
//     });

//     return () => {
//       off(messagesRef, 'value', onMessageValue);
//     };
//   }, []);

//   const formatTimestamp = (timestamp) => {
//     const date = new Date(timestamp);
//     const hours = date.getHours();
//     const minutes = date.getMinutes();
//     const period = hours >= 12 ? 'PM' : 'AM';
//     const formattedHours = hours % 12 || 12; // Convert to 12-hour format
//     const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
//     return formattedTime;
//   };

//   return (
//     <div style={{backgroundImage: 'linear-gradient(103.22deg, #db8eff -13.86%, #0aefe9 99.55%)',
//     borderRadius: '15px',padding: '4px' }} className="container__DataTable "> 
//       <div className="Horizon__DataTable" id="DataTable">
//         <div className='Horizon__DataTable-content'>
//           <h1 className="gradient__text">Scan Data</h1>
//           <table className='ScanData_Table' style={{Align:'center'}}>
//             <thead>
//               <tr>
//                 <th style={{ padding: '10px' }}>File Name</th>
//                 <th style={{ paddingLeft: '25px' ,paddingRight:'25px',paddingTop:'10px',paddingBottom:'10px' }}>File Health</th>
//                 <th style={{ paddingLeft: '25px' ,paddingRight:'25px',paddingTop:'10px',paddingBottom:'10px' }}>Scan Time</th>
//               </tr>
//             </thead>
//             <tbody>
//               {messages.map((message, index) => (
//                 <tr key={index}>
//                   <td style={{ padding: '10px' }}>{message.file_name}</td>
//                   <td style={{ paddingLeft: '25px' ,paddingRight:'25px',paddingTop:'10px',paddingBottom:'10px' }}>{message.message}</td>
//                   <td style={{ paddingLeft: '25px' ,paddingRight:'25px',paddingTop:'10px',paddingBottom:'10px' }}>{formatTimestamp(message.timestamp)}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DataTable;
  
import React from 'react';
// import { Article } from '../../components';

import './blog.css';
 const Blog = () => {
  return (
    <div className="Horizon__blog section__padding" id="blog">
    

    
    </div>
  
  )
}
export default Blog
import React from 'react';
import { Feature } from '../../components';
import './what.css';

 const What = () => {
  return (
    <dic className="Horizon__what section_" id="What-Is-Horizon">
      <div className="Horizon__what-feature">
        <Feature title="What is Malware Analysis" text={`>We use ClamAV to hashscan file for executable codes in files, dir(s),etc. read more `} link={<a href="https://github.com/Sawapandeep/Malware-Analysis">here</a>}    />
  {/* <p  dangerouslySetInnerHTML={{ __html: myHTML}} /> */}
      </div>
      <div className="Horizon__what-heading">
        <h1 className='gradient__text'>A Secure Space</h1>
      <p>Request Early Access to Get Started</p>
        
      </div>
      <div className='Horizon__what-container'>
      <Feature title="File Scanning" text="On Demand File Scanning , Drag'n Drop of Upload Files to get health status of your Files. UP and RUNNIG"/>
      <Feature title="Link Scanning" text="Copy'n Paste links in an input feild to scan the given links in a few seconds, for more security. UNDER DEVLOPMENT"/>
      <Feature title="Dir(s) Scanning" text="Directory Scnning ,upload a local directory for scanning malware.                    UNDER DEVELOPMENT"/>
      </div>
    </dic>
  )
}
export default What
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import axios from 'axios';
function Drpzone() {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');

  const handleDrop = (acceptedFiles) => {
    // Upload the file to the server using Axios
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    axios.post('http://127.0.0.1:5000/scan', formData)
      .then((response) => {
        // Display the scan result
        setMessage(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    // Set the file state to the uploaded file
    setFile(acceptedFiles[0]);
  };

  return (
    <div className='flex' style={{alignItems:'center'}}>
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p style={{  fontFamily:'var(--font-family)',fontWeight:'500',fontSize:'20px',lineHeight:'38px',color:'azure',textAlign:'center'}}>Drag and drop a file here, or click to select a file</p>
          </div>
        )}
      </Dropzone>

      {/*{message}*/}
    </div>
  );
}

export default Drpzone;

//!!!!
